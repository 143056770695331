import React from "react";
import { buttonInterface } from "Interfaces";

const iconArray: { key: string; value: string }[] = [
  { key: "linked-in", value: 'fab fa-linkedin-in"' },
  { key: "email", value: 'far fa-envelope"' },
];

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function getIconName(icon) {
  if (icon === undefined) return;
  return getKeyByValue(iconArray, icon);
}

function renderIcon(icon) {
  return <i className={getIconName(icon)}></i>;
}

function Button(props: buttonInterface) {
  return (
    <button className={props.type}>
      {props.text}
      {renderIcon(props.socialType)}
    </button>
  );
}

export default Button;
