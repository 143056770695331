import { NavLink } from "react-router-dom";
import React from "react";

function Navigation(props: any) {
  return (
    <div>
      <ul className="navigation">
        {props.items.map((item: any, id: any) => (
          <li key={id}>
            <NavLink
              exact={true}
              key={id}
              activeClassName="selected"
              to={item.url}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Navigation;
