import Typewriter from "typewriter-effect";
import React from "react";
function TopMainSection() {
  return (
    <div className="topMain">
      <h1>Hi! I'm Kate and I'm</h1>

      <div>
        {" "}
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString("ux")
              .pauseFor(500)
              .deleteAll()
              .typeString("ui")
              .pauseFor(500)
              .deleteAll()
              .typeString("visual")
              .pauseFor(500)
              .deleteAll()
              .typeString("product")
              .pauseFor(500)
              .start();
          }}
        />
        <span className="designer">design</span>
        <div className="ent">enthusiast</div>
      </div>
    </div>
  );
}

export default TopMainSection;
