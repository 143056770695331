import Navigation from "./Navigation";
import "./main.scss";
import Home from "Home";
import { navigationItemInterface } from "./Interfaces";
import React from "react";
import { AnimatedSwitch } from "react-router-transition";
import { Switch, Route } from "react-router-dom";
import About from "About";
import ProjectDetail from "ProjectDetail";
const items: Array<navigationItemInterface> = [
  {
    name: "Portfolio",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
];

function App() {
  return (
    <div className="container">
      <Navigation items={items}></Navigation>

      <Switch>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 1 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/about" title="Learn more about Katarzyna Romańska - UX Designer, Mentor, and NN/g Certified Professional" component={About} />
          <Route exact={true} path="/" component={Home} />
          <Route path="/projects/:projectName/" component={ProjectDetail} />
        </AnimatedSwitch>
      </Switch>
    </div>
  );
}

export default App;
