import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function About(props: any) {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  return (
    <Carousel>
      <div className="about">

        <div className="about-image">
          <img src={`${process.env.PUBLIC_URL}/images/me.jpeg`} alt="" />
        </div>
        <div className="about-content">
          <h1>Hello!</h1>
          <p>I am Kate, a designer with over 6 years of experience in developing digital solutions for smaller companies, such as Grupa Dealer (automotive), where I served as a one-person UX team and also as team member of large organizations, including Global Logic and various high-tech industries (Analytik Jena, Endress+Hauser).</p>
          <p>Currently, I am Product Designer at Accenture, where I collaborate with clients from diverse sectors, including banking, telecom, and the tobacco industry.</p>
          <div className="about-social">
            <a
              href="mailto:katarzyna.romanskaa@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-icon">
                <i className="far fa-envelope"></i>
              </div>
            </a>
            <a
              href="https://www.behance.net/romanskaa"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-icon">
                <i className="fab fa-behance"></i>
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/katarzyna-roma%C5%84skaa"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div>
        <h1 className="slide-header">Current focus</h1>
        <div className="about-slide">

          <div className="grid-item accenture">
            <h2><img className="ikon" src={`${process.env.PUBLIC_URL}/images/icons/accenture.png`} alt="" />Accenture</h2>
            <h3>Tobacco Industry</h3>
            <ul>
              <li>Internal and external b2b application (KPIs dashboard on Power BI, Farmer, SAP CPQ)</li>
              <li>User Interviews, creating IA, user journeys, style guide for products</li>
              <li>Closely working with PO and team</li>
            </ul>
            <h3>Telecom</h3>
            <ul>
              <li>Requirements & planning with PO</li>
              <li>Closely working with Conversation Designers and Watson devs on establishing best user experience for billing journeys (AI chatbot)</li>
              <li>Prototypes for usability tests and working with research team on creating scenarios</li>
              <li>UI components based on Design System and developers possibilities</li>
            </ul>
          </div>
          <div className="grid-item mentorship">
            <h2><img className="ikon dm" src={`${process.env.PUBLIC_URL}/images/icons/dm-logo.png`} alt="" />Design Mentorship</h2>
            <h3>UX Mentor</h3>
            <p>Outside my daily work I’m also UX/UI Mentor, where I can help aspiring designers understand the ins and outs of the profession.</p>
          </div>
          <div className="grid-item fun">
            <h2><img className="ikon dm" src={`${process.env.PUBLIC_URL}/images/icons/hobby.png`} alt="" />Hobby</h2>
            <h3>Having fun!</h3>
            <ul>
              <li>Games (Mario Kart fan!)</li>
              <li>Polymer clay jewelry</li>
              <li>Violin, cozy evenings with books <br /> & cocoa & my cat purring</li>
              <li>Sunny days enthusiast</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="slide">
        <h1 className="slide-header">My Guideline</h1>
        <div className="about-slide --two">
          <div className="grid-item">
            <h2><img className="ikon dm" src={`${process.env.PUBLIC_URL}/images/icons/language.png`} alt="" />Embrace Empathy, Not Emotion</h2>
            <p>When working on designs, make sure you really understand what users need and try to see things from their perspective. Create solutions that work well for them, and don't let your own feelings get in the way when problems come up. </p>
          </div>
          <div className="grid-item">
            <h2><img className="ikon dm" src={`${process.env.PUBLIC_URL}/images/icons/workspaces.png`} alt="" />Transparent & Respectful Collaboration</h2>
            <p>Talk clearly and keep everyone updated on how the project is going. Make sure everyone is treated with respect. This way, everyone works well together, and we keep moving forward smoothly. </p>
          </div>
          <div className="grid-item">
            <h2><img className="ikon dm" src={`${process.env.PUBLIC_URL}/images/icons/plan.png`} alt="" />Iterative Flexibility</h2>
            <p>Keep improving your designs by trying things out, getting feedback, and making changes. Be ready to switch up your plan based on what you learn. This keeps your work fresh and makes sure it does what users need. </p>
          </div>
          <div className="grid-item">
            <h2><img className="ikon dm" src={`${process.env.PUBLIC_URL}/images/icons/edit.png`} alt="" />Creative Drive</h2>
            <p>Look for new ideas and stay excited about your work. Use different things to spark your creativity and keep loving what you do. This shows how important it is to be curious and happy in your work.</p>
          </div>
        </div>
      </div>
    </Carousel >

  );
}

export default About;
