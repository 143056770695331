import { portfolioGridItemInterface } from "./Interfaces";
import React from "react";

import { Link } from "react-router-dom";

function PortfolioGrid(props: any) {
  return (
    <div className="portfolio-grid-container">
      {props.items.map((item: portfolioGridItemInterface, idx: any) => (
        <Link
          to={{
            pathname: "projects/" + item.name,
          }}
          key={idx}
        >
          <div
            className="grid-item"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/project/${item.imageName})`,
            }}
          >
            <div className="item-details">
              <div>{item.text}</div>
              <div className="subheading">{item.subHeading}</div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default PortfolioGrid;
