import React, { useEffect, useState, Fragment, useMemo, useCallback } from "react";
import { useParams, Link } from "react-router-dom";

function ProjectDetail(props?: any) {
  const WalkieDoggie = useMemo(() => [
    "doggie/1920_1.jpg",
    "doggie/1920_2.jpg",
    "doggie/1920_3.jpg",
    "doggie/1920_4.jpg",
    "doggie/1920_5.jpg",
    "doggie/1920_6.jpg",
    "doggie/1920_7.jpg",
    "doggie/1920_8.jpg",
  ], []);
  const bawialnia = useMemo(() => [
    "bawialnia/1.png",
    "bawialnia/2.png",
    "bawialnia/3.png",
    "bawialnia/4.png",
    "bawialnia/5.png",
  ], []);
  const chatbot = useMemo(() => [
    "chatbot/chatbot.png",
  ], []);
  const gosia = useMemo(() => [
    "MegBeauty/1.jpg",
    "MegBeauty/2.jpg",
    "MegBeauty/3.jpg",
    "MegBeauty/4.jpg",
    "MegBeauty/5.jpg",
  ], []);

  const b2b = useMemo(() => [
    "b2b/1.jpg",
    "b2b/2.jpg",
    "b2b/3.jpg",
    "b2b/4.jpg",
    "b2b/5.jpg",
    "b2b/6.jpg",
  ], []);

  const deskset = useMemo(() => [
    "deskset/1.png",
    "deskset/2.png",
    "deskset/3.png",
    "deskset/4.png",
    "deskset/5.png",
    "deskset/6.png",
    "deskset/7.png",
    "deskset/8.png",
    "deskset/9.png",
    "deskset/10.png",
    "deskset/11.png",
  ], []);

  const makesend = useMemo(() => [
    "makesend/1.png",
    "makesend/2.jpg",
    "makesend/3.jpg",
    "makesend/4.jpg",
    "makesend/5.jpg",
    "makesend/6.jpg",
  ], []);
  const tobacco = useMemo(() => [
    "tobacco/tobacco-1.png",
  ], []);
  const crm = useMemo(() => [
    "DealerCRM/1.png",
    "DealerCRM/1.1.png",
    "DealerCRM/2.png",
    "DealerCRM/3.png",
    "DealerCRM/4.png",
    "DealerCRM/5.png",
    "DealerCRM/6.png",
    "DealerCRM/7.png",
    "DealerCRM/8.png",
  ], []);

  const dealerbit = useMemo(() => ["dealerbit/1.png"], []);

  const projectArray = useMemo(() => ({
    dealerbit: dealerbit,
    DeskSet: deskset,
    Bawialnia: bawialnia,
    WalkieDoggie: WalkieDoggie,
    DealerCRM: crm,
    MegBeauty: gosia,
    b2b: b2b,
    makesend: makesend,
    chatbot: chatbot,
    tobacco: tobacco
  }), [dealerbit, deskset, bawialnia, WalkieDoggie, crm, gosia, b2b, makesend, chatbot, tobacco]);

  let { projectName } = useParams();
  let [projectImages, setProjectImages] = useState([""]);

  const loadImages = useCallback(() => {
    Object.keys(projectArray).forEach((key) => {
      if (key === projectName) {
        setProjectImages(projectArray[key]);
      }
    });
  }, [projectArray, projectName]);

  useEffect(() => {
    loadImages();
  }, [projectArray, projectName, loadImages]);

  function scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }


  return (
    <Fragment>
      <div className="project-details">
        {projectImages &&
          projectImages.map((image, id) => (
            <img
              className="project-img"
              alt=""
              key={id}
              src={`${process.env.PUBLIC_URL}/images/project/${image}`}
            ></img>
          ))}
      </div>
      <div className="close-project">
        <Link to="/">
          <i className="fas fa-times-circle"></i>
        </Link>
      </div>

      <div onClick={scrollTop} className="scroll-top">
        <i className="fas fa-chevron-circle-up"></i>
      </div>
    </Fragment>
  );
}
// }

export default ProjectDetail;
